import React from 'react';
import { IconProps } from '@/types/icon';

function ChildCareIcon({ width, height }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8595 17.125C13.6225 17.2162 8.68222 26.594 8.55161 26.5953C8.5133 26.5953 8.4773 26.5868 8.43945 26.5832C9.18853 34.2959 15.8799 40.3515 23.9998 40.3515C32.1196 40.3515 38.7482 34.3556 39.5513 26.6959C38.7639 26.5495 38.019 26.3515 37.3142 26.1139C29.2488 9.42983 15.5624 23.6517 14.8595 17.125Z"
        fill="#F3DEDC"
      />
      <path
        d="M24.2543 35.3862C22.3998 35.3862 20.7203 34.5079 19.7986 33.7512C19.3181 33.3569 19.2577 32.6577 19.6629 32.1898C20.0677 31.7241 20.7844 31.664 21.264 32.0551C21.5713 32.3039 24.3203 34.3983 26.6617 32.1215C27.1066 31.6887 27.827 31.6887 28.2715 32.1215C28.716 32.5544 28.7164 33.2554 28.2715 33.6879C26.9838 34.9408 25.5752 35.3862 24.2543 35.3862Z"
        fill="#E84E3A"
      />
      <path
        d="M39.6009 24.203C39.1052 16.2469 32.2989 9.92188 24.0005 9.92188C15.702 9.92188 8.91924 16.2258 8.40324 24.1626C8.38247 24.4837 8.36816 24.8066 8.36816 25.1326C8.36816 25.6189 8.39401 26.0994 8.44016 26.5745C8.47755 26.5781 8.51355 26.5862 8.55232 26.5867C8.68247 26.5853 9.48232 26.5871 10.7192 26.4959C14.6917 26.2036 23.184 24.9521 28.5891 19.5619C29.9502 21.4283 32.7997 24.5847 37.3149 26.1053C38.0202 26.3428 38.7651 26.5409 39.552 26.6872C39.6055 26.1758 39.6332 25.6571 39.6332 25.1326C39.6332 24.82 39.6199 24.5106 39.6009 24.203Z"
        fill="#E84E3A"
      />
      <path
        d="M24.6477 11.3697C24.5129 11.3697 24.3749 11.3459 24.2416 11.2961C23.6591 11.0774 23.3637 10.4474 23.5834 9.8775C23.6296 9.7576 24.7502 6.94287 27.8729 6.82117C28.4919 6.79737 29.0314 7.27248 29.0568 7.88366C29.0822 8.49528 28.5929 9.01081 27.9648 9.03551C26.3665 9.09748 25.7383 10.6014 25.7116 10.6656C25.5343 11.1012 25.1037 11.3697 24.6477 11.3697Z"
        fill="#E84E3A"
      />
      <path
        d="M23.6884 11.1931C23.2223 11.1931 22.7843 10.9125 22.6172 10.4603C22.0094 8.8167 20.598 8.57061 20.5389 8.56118C19.9246 8.46149 19.4977 7.89971 19.5909 7.30111C19.6837 6.70251 20.2514 6.29431 20.8615 6.36795C20.9764 6.38322 23.6737 6.77346 24.7597 9.70989C24.9734 10.2856 24.6655 10.9201 24.0738 11.1271C23.9464 11.172 23.8168 11.1931 23.6884 11.1931Z"
        fill="#E84E3A"
      />
      <path
        d="M15.9966 30.4023C16.6251 30.4023 17.1347 29.9065 17.1347 29.2949C17.1347 28.6833 16.6251 28.1875 15.9966 28.1875C15.368 28.1875 14.8584 28.6833 14.8584 29.2949C14.8584 29.9065 15.368 30.4023 15.9966 30.4023Z"
        fill="#E84E3A"
      />
      <path
        d="M32.0034 30.4023C32.632 30.4023 33.1415 29.9065 33.1415 29.2949C33.1415 28.6833 32.632 28.1875 32.0034 28.1875C31.3748 28.1875 30.8652 28.6833 30.8652 29.2949C30.8652 29.9065 31.3748 30.4023 32.0034 30.4023Z"
        fill="#E84E3A"
      />
    </svg>
  );
}

ChildCareIcon.defaultProps = {
  width: '48',
  height: '48',
};

export default ChildCareIcon;
