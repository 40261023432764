import { ReactElement } from 'react';
import { ADULT_CARE, VERTICALS, VERTICALS_DISPLAY_NAMES } from '@/constants';
import {
  ValueDriversTutoringIcon,
  ValueDriversSeniorCareIcon,
  ValueDriversPetCareIcon,
  ValueDriversHousekeepingIcon,
  ValueDriversChildCareIcon,
} from '@/components/svg/ValueDrivers';

type verticalsDisplayInfoProp = {
  iconSize?: number;
  iconColor?: string;
  isDaycareRemoved?: boolean;
  isAdultCare?: boolean;
};

interface VerticalsDisplayInfo {
  displayName: string;
  vertical: VERTICALS;
  valueDriversIcon: ReactElement | undefined;
}

const verticalsDisplayInfo = (prop?: verticalsDisplayInfoProp): VerticalsDisplayInfo[] => {
  const childcare = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.CHILD_CARE],
    vertical: VERTICALS.CHILD_CARE,
    valueDriversIcon: <ValueDriversChildCareIcon />,
  };

  const seniorcare = {
    displayName: prop?.isAdultCare ? ADULT_CARE : VERTICALS_DISPLAY_NAMES[VERTICALS.SENIOR_CARE],
    vertical: VERTICALS.SENIOR_CARE,
    valueDriversIcon: <ValueDriversSeniorCareIcon />,
  };

  const tutoring = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.TUTORING],
    vertical: VERTICALS.TUTORING,
    valueDriversIcon: <ValueDriversTutoringIcon />,
  };

  const housekeeping = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.HOUSEKEEPING],
    vertical: VERTICALS.HOUSEKEEPING,
    valueDriversIcon: <ValueDriversHousekeepingIcon />,
  };

  const daycare = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.DAY_CARE],
    vertical: VERTICALS.DAY_CARE,
    valueDriversIcon: undefined,
  };

  const petcare = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.PET_CARE],
    vertical: VERTICALS.PET_CARE,
    valueDriversIcon: <ValueDriversPetCareIcon />,
  };

  return prop?.isDaycareRemoved
    ? [childcare, seniorcare, housekeeping, petcare, tutoring]
    : [childcare, seniorcare, housekeeping, petcare, daycare, tutoring];
};
export default verticalsDisplayInfo;
