import { PROMO_CODES } from '@/constants';
import { parseURLParams } from '@/utilities/urlHelper';
import { useAppState } from '@/components/contexts/AppState';

const usePromocodeRibbon = () => {
  const { url } = useAppState();
  const pageURLParams = parseURLParams(url);
  const appliedCode = pageURLParams.cx;
  /* istanbul ignore next */
  const isPromocodeApplied = appliedCode ?? false;
  const promocodeDiscountMessage = isPromocodeApplied ? PROMO_CODES[appliedCode] : '';
  const showPromocodeRibbon =
    isPromocodeApplied && promocodeDiscountMessage !== undefined && promocodeDiscountMessage !== '';

  return {
    showPromocodeRibbon,
    promocodeDiscountMessage,
  };
};

export default usePromocodeRibbon;
