import React from 'react';
import { Typography, Box } from '@mui/material';
import ZipCodePanel from '@/components/shared/ZipCodePanel/ZipCodePanel';
import { DEFAULT_VERTICAL, LAYOUT_VARIANTS } from '@/constants';
import useTestFlags from '@/components/hooks/useTestFlags';
import useResponsive from '@/components/hooks/useResponsive';
import { handleUserNeedSelected } from '@/components/shared/HomePageTopNavigation/utils';
import { Location, SxClassProps } from '@/types';

import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import heroImage from '@/../public/hero/family.webp';
import heroImageMW from '@/../public/hero/family-MW.webp';
import { VHP_VALUE_DRIVERS_HEADER_TITLE_CTA } from '../ValueDriversConstants';

const classes: SxClassProps = {
  container: {
    padding: {
      xs: '17px 24px 205px',
      sm: '24px 24px 205px',
      md: '32px',
      lg: '40px',
    },

    display: 'flex',
    alignItems: 'stretch',
    background: {
      xs: `url(${heroImageMW.src}) no-repeat`,
      md: `url(${heroImage.src}) no-repeat`,
    },
    backgroundPosition: {
      xs: '80% 103%',
      sm: 'right bottom',
      md: 'left calc(79% + 128px) bottom -7px',
      lg: '100% 95%',
    },
    backgroundSize: {
      xs: 'auto 411px',
      sm: 'auto 400px',
      md: 'auto 522px',
    },
    maxWidth: '1920px',
    width: '100%',
    minHeight: {
      xs: 'auto',
      md: '414px',
      lg: '420px',
    },
  },
  wrapper: {
    maxWidth: '1060px',
    width: '100%',
    margin: '0 auto',
  },
  content: {
    maxWidth: {
      xs: '100%',
      sm: '645px',
    },
  },
  header: {
    fontSize: {
      xs: '32px',
      sm: '36px',
      md: '48px',
    },
    lineHeight: {
      xs: '38px',
      sm: '44px',
      md: '56px',
    },
    maxWidth: {
      xs: '400px',
      sm: '500px',
      md: 'none',
    },
  },
  zipCodePanel: {
    maxWidth: {
      xs: '100%',
      sm: '520px',
    },
  },
};

type VerticalSelectionProps = { setSelectedVertical: Function };

function Hero({ setSelectedVertical }: VerticalSelectionProps) {
  const { vhpTriageProviderVertical } = useTestFlags();
  const { isDesktop } = useResponsive();
  const routeProviderToTriagePage = vhpTriageProviderVertical?.value === 2;

  const onZipCodeSelected = (locationEntered: Location | undefined, isProvider: boolean) => {
    setSelectedVertical(DEFAULT_VERTICAL);
    // define a user needs for all users
    const userNeed = isProvider ? 'Find a job' : 'Find care';

    let zipData = {};
    if (locationEntered) {
      const { city, state, verifiedApiZipcode: zip } = locationEntered;
      zipData = {
        zip,
        city,
        state,
      };
    }

    AnalyticsHelper.logEvent({
      name: 'CTA Interacted',
      data: {
        cta_location: 'VHP Hero',
        vertical: 'Unknown',
        user_need: userNeed,
        cta_text: VHP_VALUE_DRIVERS_HEADER_TITLE_CTA.ctaText,
        ...zipData,
      },
    });

    handleUserNeedSelected(isProvider, isDesktop, routeProviderToTriagePage);
  };

  return (
    <Box sx={classes.container}>
      <Box sx={classes.wrapper}>
        <Box sx={classes.content}>
          <Typography variant="serifHeadline1" sx={classes.header}>
            {VHP_VALUE_DRIVERS_HEADER_TITLE_CTA.title}
          </Typography>
          <Box sx={classes.zipCodePanel}>
            <ZipCodePanel
              panelLayout="horizontal"
              onZipCodeSelected={onZipCodeSelected}
              withoutUserTypeSelection={false}
              transparent
              panelCtaText={VHP_VALUE_DRIVERS_HEADER_TITLE_CTA.ctaText}
              layoutVariant={LAYOUT_VARIANTS.triageTabs}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Hero;
