import { memo, useEffect } from 'react';
import VisitorHomeValueDriversLayout from '@/components/pages/visitorHome/VisitorHomeValueDriversLayout';
import VisitorHomeRebrandLayout from '@/components/pages/visitorHome/VisitorHomeRebrandLayout/VisitorHomeRebrandLayout';
import { CLIENT_FEATURE_FLAGS } from '@/constants';
import useTestFlags from '@/components/hooks/useTestFlags';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';

function VariationEvaluator() {
  const { vhpMilestone1 } = useTestFlags();

  useEffect(() => {
    AnalyticsHelper.logTestExposure(CLIENT_FEATURE_FLAGS.VHP_2_0_MILESTONE_1, vhpMilestone1);
  }, [vhpMilestone1]);

  if ([2, 3, 4].includes(vhpMilestone1?.value)) {
    return <VisitorHomeRebrandLayout />;
  }

  return <VisitorHomeValueDriversLayout />;
}

export default memo(VariationEvaluator);
