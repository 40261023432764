import React, { useState } from 'react';
import { Grid, Button, Tabs, Tab, Box } from '@mui/material';

import { VHPDataPublisher, VHPDataRetriever, ComeFrom } from '@care/mfe-data-transfer-vhp';
import { LayoutVariant, Location, SxClassProps } from '@/types';
import useEnterKey from '@/components/hooks/useEnterKey';
import { isValidZipCode } from '@/utilities/zipCodeValidators';
import { LAYOUT_VARIANTS } from '@/constants';
import ZipInput from './ZipInput';

const getClasses = (
  transparent: boolean,
  isTriageTabsLayoutVariant: boolean,
  panelLayout: string,
  isRebrandingVariant: boolean
): SxClassProps => ({
  zipCodePanelContainer: (theme) => ({
    backgroundColor: transparent ? 'transparent' : theme.palette.care?.grey[50],
    borderColor: transparent ? 'transparent' : theme.palette.care?.grey[50],
    borderWidth: '1px',
    borderStyle: 'solid',
  }),

  zipCodeTabContainer: [
    (theme) => ({
      marginTop: isTriageTabsLayoutVariant ? 3 : 2,
      marginBottom: {
        xs: '12px',
        lg: isTriageTabsLayoutVariant ? 4 : 2,
      },
      '& .MuiTab-root': {
        width: '50%',
        maxWidth: '50%',
        minWidth: '50%',
        fontSize: '18px',

        lineHeight: '24px',
        textTransform: 'none',
        borderBottomStyle: 'none',
        paddingBottom: '8px',

        '&[aria-selected="true"]': {
          color: theme.palette.care.red[500],
        },
      },
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.care.red[500],
      },
    }),
    ['horizontal', 'fixedWidth'].includes(panelLayout) && {
      maxWidth: {
        xs: '231px',
        md: '242px',
        lg: '276px',
      },

      marginBottom: {
        xs: '2px',
      },

      '& .MuiTab-root': {
        padding: {
          xs: '6px 12px 8px',
        },
        fontSize: {
          xs: '16px',
          md: '18px',
        },

        lineHeight: {
          xs: '22px',
          md: '24px',
        },
      },
    },
    isRebrandingVariant &&
      ((theme) => ({
        margin: {
          xs: theme.spacing(0, 0, 2),
        },

        '& .MuiTab-root': {
          padding: '0',
          marginRight: 4,
          fontSize: {
            xs: '18px',
            sm: '21px',
          },

          minWidth: 'auto',
          width: {
            xs: 'auto',
          },
        },
      })),
  ],
  zipCodeFieldContainer: [
    panelLayout === 'horizontal' &&
      ((theme) => ({
        [theme.breakpoints.down('sm')]: {
          marginBottom: 2,
        },
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '58%',
        },
      })),
  ],

  zipCodeCTAContainer: [
    {
      paddingTop: 1,
      '& .MuiButton-root': {
        width: '100%',
      },
    },
    panelLayout === 'horizontal' &&
      ((theme) => ({
        [theme.breakpoints.down('sm')]: {
          paddingTop: 0,
        },

        [theme.breakpoints.up('sm')]: {
          width: '39%',
          paddingTop: '6px',
          marginBottom: '38px',
        },
      })),
    panelLayout === 'fixedWidth' && {
      paddingTop: 0,
    },
    isRebrandingVariant && {
      paddingTop: 3,
    },
  ],
  horizontalGrid: [
    panelLayout === 'horizontal' &&
      ((theme) => ({
        marginTop: 3,
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginTop: 2,
        },
      })),
  ],
});

type PanelLayout = 'default' | 'horizontal' | 'fixedWidth';

interface ZipCodePanelProps {
  onZipCodeSelected: (location: Location | undefined, isProvider: boolean) => void;
  panelLayout?: PanelLayout;
  panelCtaText?: string;
  transparent?: boolean;
  className?: string;
  withoutUserTypeSelection?: boolean;
  layoutVariant?: LayoutVariant;
  iconLeft?: boolean;
}

function ZipCodePanel(props: ZipCodePanelProps) {
  const {
    onZipCodeSelected,
    transparent = false,
    panelLayout = 'default',
    panelCtaText = 'Search',
    className,
    withoutUserTypeSelection,
    layoutVariant,
    iconLeft = false,
  } = props;
  const isTriageTabsLayoutVariant = layoutVariant === LAYOUT_VARIANTS.triageTabs;
  const isRebrandingVariant = layoutVariant === LAYOUT_VARIANTS.rebranding;

  const classes = getClasses(
    transparent,
    isTriageTabsLayoutVariant,
    panelLayout,
    isRebrandingVariant
  );
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [enterKeyEnabled, setEnterKeyEnabled] = useState<boolean>(false);
  const [locationEntered, setLocationEntered] = useState<Location | undefined>(undefined);

  const locationWrappedOnZipCodeSelected = () => {
    const VHPRetriever = new VHPDataRetriever();
    const existingData = VHPRetriever.retrieveData();
    const data = {
      ...existingData,
      comeFrom: ComeFrom.VHP,
    };
    const VHPPublisher = new VHPDataPublisher();

    VHPPublisher.publishData(data);
    onZipCodeSelected(locationEntered, selectedTab === 1);
  };

  const handleZipCodeFieldChange = (location: Location) => {
    if (
      location.verifiedApiZipcode &&
      location.city &&
      location.state &&
      isValidZipCode(location.verifiedApiZipcode)
    ) {
      setLocationEntered(location);
      const VHPRetriever = new VHPDataRetriever();
      const existingData = VHPRetriever.retrieveData();
      const data = {
        ...existingData,
        zipcodeData: {
          zipcode: location.verifiedApiZipcode,
          city: location.city,
          state: location.state,
        },
        comeFrom: ComeFrom.VHP,
      };
      const VHPPublisher = new VHPDataPublisher();

      VHPPublisher.publishData(data);
    }
    if (location.inputZipcode) {
      setEnterKeyEnabled(location.inputZipcode.length > 0);
    } else {
      setEnterKeyEnabled(false);
    }
  };

  const handleTabStateChange = (event: object, value: any) => {
    setSelectedTab(parseInt(value, 10));
  };

  useEnterKey(enterKeyEnabled, () => locationWrappedOnZipCodeSelected());

  return (
    <form className={className}>
      <Box sx={classes.zipCodePanelContainer}>
        {!withoutUserTypeSelection && (
          <Box sx={classes.zipCodeTabContainer}>
            <Tabs value={selectedTab} onChange={handleTabStateChange}>
              <Tab label="Find care" disableRipple />
              <Tab label="Find a job" disableRipple />
            </Tabs>
          </Box>
        )}

        <Grid direction="column" sx={classes.horizontalGrid} container>
          <Box sx={classes.zipCodeFieldContainer}>
            <ZipInput
              onChange={handleZipCodeFieldChange}
              validateOnLostFocusOrClick
              validateOnClick={false}
              withIntegratedCta={false}
              handleZipCodeButtonClick={locationWrappedOnZipCodeSelected}
              showLocationIcon
              iconLeft={iconLeft}
            />
          </Box>
          <Box sx={classes.zipCodeCTAContainer}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={locationWrappedOnZipCodeSelected}>
              {panelCtaText}
            </Button>
          </Box>
        </Grid>
      </Box>
    </form>
  );
}

export default ZipCodePanel;
