import React from 'react';
import { IconProps } from '@/types/icon';

function TutoringIcon({ width, height }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.3466 39.1657H13.5277C12.3997 39.1657 11.4854 38.2514 11.4854 37.1234V8.99543C11.4854 7.86743 12.3997 6.95312 13.5277 6.95312H32.999C35.1041 9.0582 36.2843 10.2384 38.3889 12.343V37.1234C38.3889 38.2514 37.4746 39.1657 36.3466 39.1657Z"
        fill="#F3DEDC"
      />
      <path
        d="M32.9765 6.95359L32.9405 10.9639C32.9349 11.5542 33.3923 12.0457 33.9817 12.0836L38.388 12.3647L32.9765 6.95312V6.95359Z"
        fill="#E84E3A"
      />
      <path
        d="M34.4585 16.3407H15.4155C15.1685 16.3407 14.9678 16.1404 14.9678 15.893C14.9678 15.6456 15.1681 15.4453 15.4155 15.4453H34.4585C34.7055 15.4453 34.9062 15.6456 34.9062 15.893C34.9062 16.1404 34.7059 16.3407 34.4585 16.3407Z"
        fill="#E84E3A"
      />
      <path
        d="M34.4585 20.3251H15.4155C15.1685 20.3251 14.9678 20.1248 14.9678 19.8774C14.9678 19.63 15.1681 19.4297 15.4155 19.4297H34.4585C34.7055 19.4297 34.9062 19.63 34.9062 19.8774C34.9062 20.1248 34.7059 20.3251 34.4585 20.3251Z"
        fill="#E84E3A"
      />
      <path
        d="M34.4585 24.661H15.4155C15.1685 24.661 14.9678 24.4607 14.9678 24.2133C14.9678 23.9659 15.1681 23.7656 15.4155 23.7656H34.4585C34.7055 23.7656 34.9062 23.9659 34.9062 24.2133C34.9062 24.4607 34.7059 24.661 34.4585 24.661Z"
        fill="#E84E3A"
      />
      <path
        d="M34.4585 28.9972H15.4155C15.1685 28.9972 14.9678 28.7969 14.9678 28.5495C14.9678 28.3021 15.1681 28.1018 15.4155 28.1018H34.4585C34.7055 28.1018 34.9062 28.3021 34.9062 28.5495C34.9062 28.7969 34.7059 28.9972 34.4585 28.9972Z"
        fill="#E84E3A"
      />
      <path
        d="M30.9859 33.3329H15.4155C15.1685 33.3329 14.9678 33.1326 14.9678 32.8852C14.9678 32.6378 15.1681 32.4375 15.4155 32.4375H30.9859C31.2329 32.4375 31.4336 32.6378 31.4336 32.8852C31.4336 33.1326 31.2333 33.3329 30.9859 33.3329Z"
        fill="#E84E3A"
      />
      <path
        d="M24.788 40.8906C23.7472 41.1191 22.7881 41.8017 22.2412 42.7742L23.9835 43.7134C24.6574 44.0766 25.4411 43.4651 25.2528 42.7234L24.788 40.8906Z"
        fill="#E84E3A"
      />
      <path
        d="M24.7875 40.8828L23.8446 37.1665L11.1135 20.8438L6.21289 24.6662L18.944 40.989L22.2407 42.7659C22.7877 41.7939 23.7467 41.1113 24.7875 40.8828Z"
        fill="#EB998A"
      />
      <path
        d="M6.4689 19.9469C5.11567 21.0024 4.87428 22.9552 5.92982 24.3084L6.82521 25.4563C7.84197 23.3618 9.46936 21.9592 11.7258 21.6338L10.8304 20.4855C9.7749 19.1323 7.82213 18.8909 6.4689 19.9464V19.9469Z"
        fill="#EB998A"
      />
      <path
        d="M23.8446 37.1665L11.1135 20.8438L6.21289 24.6662L18.944 40.989C18.944 40.989 19.5735 37.6839 23.8446 37.1665Z"
        fill="#E84E3A"
      />
    </svg>
  );
}

TutoringIcon.defaultProps = {
  width: '48',
  height: '48',
};

export default TutoringIcon;
