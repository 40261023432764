import { Dispatch, SetStateAction } from 'react';
import { Box, useTheme } from '@mui/material';

import WaveDivider, { POSITION } from '@/components/shared/WaveDivider/WaveDivider';
import { LayoutVariant, SxClassProps } from '@/types';
import VerticalSelection from './VerticalSelection';

const classes: SxClassProps = {
  waveContainer: {
    position: 'relative',
  },
  verticalsContainer: (theme) => ({
    position: 'relative',
    backgroundColor: theme.palette.care?.latte[100],
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(4),
    },

    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(7),
    },
  }),
};

type VerticalSelectionWithWaveDividerProps = {
  setSelectedVertical: Function;
  setOpenModalOrDrawer: Dispatch<SetStateAction<boolean>>;
  layoutVariant: LayoutVariant;
};

function VerticalSelectionWithWaveDivider({
  setSelectedVertical,
  setOpenModalOrDrawer,
  layoutVariant,
}: VerticalSelectionWithWaveDividerProps) {
  const theme = useTheme();
  return (
    <>
      <Box sx={classes.waveContainer}>
        <WaveDivider
          position={POSITION.ABSOLUTE}
          backgroundColor="transparent"
          shapeFill={theme.palette.care?.latte[100]}
          attachToBottom
        />
      </Box>
      <Box sx={classes.verticalsContainer}>
        <VerticalSelection
          setSelectedVertical={setSelectedVertical}
          setOpenModalOrDrawer={setOpenModalOrDrawer}
          layoutVariant={layoutVariant}
        />
      </Box>
    </>
  );
}

export default VerticalSelectionWithWaveDivider;
