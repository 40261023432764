import { gql } from '@apollo/client';

const FIND_CARE_GROUPS_BY_PARTIAL_NAME = gql`
  query findCareGroupsByPartialName($partialName: String!) {
    findCareGroupsByPartialName(partialName: $partialName) {
      ... on CareGroupInfo {
        name
        shortName
        url
      }
    }
  }
`;

const GET_ZIP_CODE_SUMMARY = gql`
  query getZipcodeSummary($zipcode: String) {
    getZipcodeSummary(zipcode: $zipcode) {
      ... on ZipcodeSummary {
        __typename
        city
        state
        zipcode
      }

      ... on InvalidZipcodeError {
        __typename
        message
      }
    }
  }
`;

const GET_REFERRER_DETAILS = gql`
  query GetReferrerDetails($referrerId: ID!) {
    getReferrerDetails(referrerId: $referrerId) {
      id
      rx1
      rx2
      rx3
      rx4
      rx5
      rx6
      rx7
      rx8
      rx9
      rx10
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { FIND_CARE_GROUPS_BY_PARTIAL_NAME, GET_ZIP_CODE_SUMMARY, GET_REFERRER_DETAILS };
