import React, { SyntheticEvent, useMemo } from 'react';
import { Button, Box, Link } from '@mui/material';
import { VHPDataPublisher, VHPDataRetriever, ComeFrom } from '@care/mfe-data-transfer-vhp';
import useTestFlags from '@/components/hooks/useTestFlags';
import useResponsive from '@/components/hooks/useResponsive';
import ProfileIcon from '@/components/svg/ProfileIcon';
import { Layout, SxClassProps } from '@/types';
import { LAYOUTS } from '@/constants';
import { applyToJobsHandler, logInHandler } from './utils';

type TopNavigationMWProps = {
  verticalsView: boolean;
  toggleModal?: () => void;
  layout?: Layout;
};

const classes: SxClassProps = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  applyToJob: (theme) => ({
    fontSize: '12px',
    marginRight: theme.spacing(2),
    '@media (max-width: 600px)': {
      marginRight: theme.spacing(1),
      width: '100px',
    },
  }),
  applyToJobsLink: (theme) => ({
    cursor: 'pointer',
    marginRight: {
      xs: theme.spacing(3),
      sm: theme.spacing(4),
    },
  }),
  button: {
    '@media (max-width: 360px)': {
      width: '98px',
    },
  },
  zipCodeLoginButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    alignItems: 'center',
    border: 'none',
    paddingLeft: '0px',
    paddingRight: '0px',
    marginRight: '-2px',
  },
  zipCodeLoginText: {
    fontSize: '10px',
    lineHeight: '14px',
  },
};

const LoginButtonComponent = ({
  handleClick,
}: {
  handleClick: React.EventHandler<SyntheticEvent>;
}) => {
  return (
    <Button
      color="primary"
      variant="outlined"
      size="small"
      onClick={handleClick}
      sx={classes.button}
      data-testid="login">
      Log in
    </Button>
  );
};

const LoginLinkComponent = ({
  handleClick,
}: {
  handleClick: React.EventHandler<SyntheticEvent>;
}) => {
  const { isMobile } = useResponsive();
  return (
    <Link
      sx={{ cursor: 'pointer' }}
      variant={isMobile ? 'body2' : 'body3'}
      onClick={handleClick}
      data-testid="login"
      color="inherit">
      Log in
    </Link>
  );
};

const ZipcodeLayoutButton = ({
  handleClick,
}: {
  handleClick: React.EventHandler<SyntheticEvent>;
}) => {
  return (
    <Button type="button" onClick={handleClick} sx={classes.zipCodeLoginButtonWrapper}>
      <ProfileIcon width="20" height="20" />
      <Box component="span" sx={classes.zipCodeLoginText}>
        Log in
      </Box>
    </Button>
  );
};

const ApplyToJobsButtonComponent = ({
  handleClick,
}: {
  handleClick: React.EventHandler<SyntheticEvent>;
}) => {
  return (
    <Button
      color="secondary"
      variant="outlined"
      size="small"
      sx={classes.applyToJob}
      onClick={handleClick}>
      Apply to jobs
    </Button>
  );
};

const ApplyToJobsLinkComponent = ({
  handleClick,
}: {
  handleClick: React.EventHandler<SyntheticEvent>;
}) => {
  const { isMobile } = useResponsive();

  return (
    <Link
      variant={isMobile ? 'body2' : 'body3'}
      onClick={handleClick}
      color="inherit"
      data-testid="Apply to jobs"
      sx={classes.applyToJobsLink}>
      Apply to jobs
    </Link>
  );
};

function TopNavigationMW({
  verticalsView,
  toggleModal,
  layout = LAYOUTS.default,
}: TopNavigationMWProps) {
  const { applyToJobsModal, vhpTriageProviderVertical } = useTestFlags();
  const routeProviderToTriagePage = vhpTriageProviderVertical?.value === 2;
  const VHPPublisher = new VHPDataPublisher();
  const VHPRetriever = new VHPDataRetriever();
  const existingData = VHPRetriever.retrieveData();

  const onClickHandler = () => {
    logInHandler();
  };

  const onApplyToJobsClick = () => {
    if (applyToJobsModal?.value && toggleModal) {
      return toggleModal();
    }
    const data = { ...existingData, comeFrom: ComeFrom.VHP };

    VHPPublisher.publishData(data);
    return applyToJobsHandler(true, undefined, routeProviderToTriagePage);
  };

  const ApplyToJobs = useMemo(() => {
    switch (layout) {
      case LAYOUTS.valueDrivers:
        return ApplyToJobsLinkComponent;
      case LAYOUTS.rebranding:
        return ApplyToJobsLinkComponent;
      default:
        return ApplyToJobsButtonComponent;
    }
  }, [layout]);

  const LoginButton = useMemo(() => {
    switch (layout) {
      case LAYOUTS.zipCode:
        return ZipcodeLayoutButton;
      case LAYOUTS.valueDrivers:
        return LoginLinkComponent;
      case LAYOUTS.rebranding:
        return LoginLinkComponent;
      default:
        return LoginButtonComponent;
    }
  }, [layout]);

  return (
    <Box sx={classes.container}>
      {verticalsView && <ApplyToJobs handleClick={onApplyToJobsClick} />}
      <LoginButton handleClick={onClickHandler} />
    </Box>
  );
}

export default TopNavigationMW;
