// External dependencies
import { useMediaQuery, useTheme } from '@mui/material';

function useResponsive() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints?.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints?.only('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints?.up('md'));
  const isDesktopLG = useMediaQuery(theme.breakpoints?.up('lg'));
  const isMedium = useMediaQuery(theme.breakpoints?.only('md'));

  return {
    isMobile,
    isTablet,
    isDesktop,
    isDesktopLG,
    isMedium,
  };
}

export default useResponsive;
