import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import dynamic from 'next/dynamic';
import { Box } from '@mui/material';
import { VHPDataPublisher, VHPDataRetriever } from '@care/mfe-data-transfer-vhp';
import logger from '@/lib/clientLogger';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import {
  CZEN_SESSION_COOKIE_KEY,
  CZEN_VISITOR_COOKIE_KEY,
  LAYOUTS,
  GTM_SLOTS,
  VerticalSelection,
  UNKNOWN_VERTICAL,
  CLIENT_FEATURE_FLAGS,
} from '@/constants';
import { useAppDispatch } from '@/components/contexts/AppState';
import PromocodeRibbon from '@/components/shared/PromocodeRibbon/PromocodeRibbon';
import usePromocodeRibbon from '@/components/hooks/usePromocodeRibbon';
import useResponsive from '@/components/hooks/useResponsive';
import useRxCodes from '@/components/hooks/useRxCodes';
import HeaderWithSEOMegaNav from '@/components/shared/HeaderWithSEOMegaNav';
import withLazyLoad from '@/utilities/withLazyLoad';
import { AnalyticsEventName, ScreenName } from '@/types/enums';
import useTestFlags from '@/components/hooks/useTestFlags';
import Hero from './Hero/Hero';
import VerticalSelectionBlock from './VerticalSelection/VerticalSelection';

// Dettaching this code from the actual bundle
const CaregiverReviews = dynamic(() => import('./CaregiverReviews/CaregiverReviews'), {
  ssr: true,
});
const HowItWorks = dynamic(() => import('./HowItWorks/HowItWorks'), { ssr: true });
const Safety = dynamic(() => import('./Safety/Safety'), { ssr: true });
const MembershipPlan = dynamic(() => import('./MembershipPlan/MembershipPlan'), { ssr: true });
const HomePageFooter = dynamic(() => import('@/components/shared/HomePageFooter'), { ssr: true });
const MemberSelection = dynamic(() => import('@/components/shared/MemberSelection'), {
  ssr: false,
});

// NOTE: with dynamic imports the Component default displayName is not available and Next's generic name "LoadableComponent" is assigned
// For better debugging define a unique display name
CaregiverReviews.displayName = 'LoadableComponent.CaregiverReviews';
HowItWorks.displayName = 'LoadableComponent.HowItWorks';
Safety.displayName = 'LoadableComponent.Safety';
MembershipPlan.displayName = 'LoadableComponent.MembershipPlan';

// Using HOC withLazyLoad to load the component when is on screen
const CaregiverReviewsWithLazyLoad = withLazyLoad(CaregiverReviews);
const HowItWorksWithLazyLoad = withLazyLoad(HowItWorks, 0);
const MembershipPlanWithLazyLoad = withLazyLoad(MembershipPlan, 0);
const SafetyWithLazyLoad = withLazyLoad(Safety);
const FooterWithLazyLoad = withLazyLoad(HomePageFooter);
const MemberSelectionWithLazyLoad = withLazyLoad(MemberSelection);

const LAYOUT = LAYOUTS.rebranding;

function VisitorHomeRebrandLayout() {
  const [selectedVertical, setSelectedVertical] = useState<VerticalSelection>(UNKNOWN_VERTICAL);
  const [openModalOrDrawer, setOpenModalOrDrawer] = useState<boolean>(false);
  const { showPromocodeRibbon, promocodeDiscountMessage } = usePromocodeRibbon();
  const { isDesktop } = useResponsive();
  const { rxData, rxDataDoneLoading } = useRxCodes();
  const { adultCareTest } = useTestFlags();
  const dispatch = useAppDispatch();
  const cookies = new Cookies();
  const czenVisitorId = cookies.get(CZEN_VISITOR_COOKIE_KEY);
  const czenSessionId = cookies.get(CZEN_SESSION_COOKIE_KEY);
  const VHPPublisher = new VHPDataPublisher();
  const VHPRetriever = new VHPDataRetriever();
  const existingData = VHPRetriever.retrieveData();

  useEffect(() => {
    AnalyticsHelper.logTestExposure(CLIENT_FEATURE_FLAGS.GROWTH_VHP_ADULT_CARE, adultCareTest);

    const updatedSharedData = {
      ...existingData,
      featureFlagSharedData: {
        ...existingData?.featureFlagSharedData,
        [CLIENT_FEATURE_FLAGS.GROWTH_VHP_ADULT_CARE]: adultCareTest?.value ?? null,
      },
    };
    VHPPublisher.publishData(updatedSharedData);

    if (adultCareTest?.value === 2) {
      dispatch({ type: 'setIsAdultCare', isAdultCare: true });
    }
  }, [adultCareTest]);

  useEffect(() => {
    if (rxDataDoneLoading) {
      AnalyticsHelper.logEvent({
        name: AnalyticsEventName.HOMEPAGE_VIEWED,
        data: {
          slots: [GTM_SLOTS.VHP],
          seo_page_id: '1000',
          screen_name: ScreenName.VISITOR_HOMEPAGE,
          ...rxData,
        },
      });
    }

    logger.info({
      name: AnalyticsEventName.HOMEPAGE_VIEWED,
      data: {
        screen_name: ScreenName.VISITOR_HOMEPAGE,
        layout: LAYOUT,
        czenVisitorId,
        czenSessionId,
      },
    });
  }, [czenSessionId, czenVisitorId, rxData, rxDataDoneLoading]);

  return (
    <Box data-testid={LAYOUT}>
      {/* GROW-10073 */}
      {showPromocodeRibbon && <PromocodeRibbon discountMessage={promocodeDiscountMessage} />}
      <Box
        display="flex"
        justifyContent="center"
        borderBottom={(theme) => `1px solid ${theme.palette.care?.grey[300]}`}>
        <HeaderWithSEOMegaNav layout={LAYOUT} />
      </Box>

      {/* GROW-10214 */}
      <Hero />

      {/* GROW-10077 */}
      <VerticalSelectionBlock
        setSelectedVertical={setSelectedVertical}
        setOpenModalOrDrawer={setOpenModalOrDrawer}
      />

      {/* Below the fold  */}
      {/* GROW-10079 */}
      <CaregiverReviewsWithLazyLoad
        setSelectedVertical={setSelectedVertical}
        setOpenModalOrDrawer={setOpenModalOrDrawer}
      />

      {/* GROW-10081 */}
      <SafetyWithLazyLoad />

      {/* GROW-10215 */}
      <MembershipPlanWithLazyLoad />

      {/* GROW-10080 */}
      <HowItWorksWithLazyLoad setOpenModalOrDrawer={setOpenModalOrDrawer} />

      {/* Member selection */}
      {/* GROW-10077 */}
      <MemberSelectionWithLazyLoad
        selectedVertical={selectedVertical}
        openModalOrDrawer={openModalOrDrawer}
        setOpenModalOrDrawer={setOpenModalOrDrawer}
        isVhpMilestone1
      />

      <FooterWithLazyLoad renderDesktopView={isDesktop} />
    </Box>
  );
}

export default VisitorHomeRebrandLayout;
