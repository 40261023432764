import { useEffect, useReducer, useCallback, Reducer } from 'react';
import { useLazyQuery } from '@apollo/client';
import produce from 'immer';
import logger from '@/lib/clientLogger';
import { isValidZipCode } from '@/utilities/zipCodeValidators';
import { getZipcodeSummary, getZipcodeSummaryVariables } from '@/__generated__/getZipcodeSummary';
import { GET_ZIP_CODE_SUMMARY } from '../request/GQL';

interface ZipState {
  city: string;
  state: string;
  zipcode: string;
  verifiedApiZipcode: string | undefined;
  helperText: string;
  error: boolean;
}

const initialState: ZipState = {
  city: '',
  state: '',
  zipcode: '',
  helperText: '',
  error: false,
  verifiedApiZipcode: undefined,
};

type ZipAction =
  | { type: 'setError'; message: string }
  | { type: 'clearResult' }
  | { type: 'setResult'; city: string; state: string; verifiedApiZipcode: string }
  | { type: 'setZipcodeHook'; zipcode: string };

const GQLcallError =
  'Sorry, we experienced an issue on our end. Please try again, then click Next.';

const zipReducer = produce(
  (draft: ZipState, action: ZipAction): ZipState => {
    switch (action.type) {
      case 'setError':
        draft.error = true;
        draft.helperText = action.message;
        return draft;
      case 'clearResult':
        draft.error = false;
        draft.helperText = '';
        draft.city = '';
        draft.state = '';
        return draft;
      case 'setResult':
        draft.error = false;
        draft.helperText = `${action.city}, ${action.state}`;
        draft.city = action.city;
        draft.state = action.state;
        draft.verifiedApiZipcode = action.verifiedApiZipcode;
        return draft;
      case 'setZipcodeHook':
        draft.zipcode = action.zipcode;
        return draft;
      default:
        return draft;
    }
  }
);

type UseZipLocation = {
  initialZipcode: string;
  validateOnLostFocusOrClick: boolean;
  populateZipOnPageEnter: boolean;
};

function useZipLocation({
  initialZipcode,
  validateOnLostFocusOrClick = false,
  populateZipOnPageEnter,
}: UseZipLocation) {
  const [zipComponentState, dispatch] = useReducer<Reducer<ZipState, ZipAction>>(zipReducer, {
    ...initialState,
    zipcode: initialZipcode,
  });
  const { zipcode } = zipComponentState;

  const [getZipCodeSummary, { variables, loading, data, error: graphQLError }] = useLazyQuery<
    getZipcodeSummary,
    getZipcodeSummaryVariables
  >(GET_ZIP_CODE_SUMMARY);

  const validateZipCode = useCallback(
    (showError: boolean) => {
      if (isValidZipCode(zipcode)) {
        getZipCodeSummary({
          variables: {
            zipcode,
          },
        });
      } else if (!isValidZipCode(zipcode) && showError) {
        dispatch({ type: 'setError', message: '' });
      } else {
        dispatch({ type: 'setError', message: '' });
      }
    },
    [dispatch, zipcode]
  );

  const setZipcodeHook = useCallback(
    (zip: string) => {
      dispatch({ type: 'setZipcodeHook', zipcode: zip });
    },
    [dispatch]
  );

  const dispatchInvalidCallResult = () => {
    logger.error({ event: 'getZipCodeSummaryError' });
    dispatch({ type: 'clearResult' });
    if (validateOnLostFocusOrClick) {
      dispatch({ type: 'setError', message: populateZipOnPageEnter ? '' : GQLcallError });
    }
  };

  const dispatchSuccessfulCallResult = () => {
    if (!data) {
      return;
    }

    const { getZipcodeSummary: getZipcodeSummaryData } = data;
    const failedValidationMessage = 'InvalidZipcodeError';

    if (getZipcodeSummaryData.__typename === failedValidationMessage) {
      return;
    }

    const { zipcode: fetchedZipcode, city, state } = getZipcodeSummaryData;

    setZipcodeHook(fetchedZipcode);
    dispatch({
      type: 'setResult',
      city,
      state,
      verifiedApiZipcode: fetchedZipcode,
    });
  };

  useEffect(() => {
    if (populateZipOnPageEnter) {
      getZipCodeSummary({});
    }
  }, []);

  useEffect(() => {
    validateZipCode(validateOnLostFocusOrClick);
  }, [validateZipCode, zipcode]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (graphQLError) {
      dispatchInvalidCallResult();
      return;
    }

    dispatchSuccessfulCallResult();
  }, [loading, variables, graphQLError]);

  return {
    zipComponentState,
    zipcode,
    loading,
    validateZipCode,
    setZipcodeHook,
  };
}

export default useZipLocation;
