import { Box, Link, Button } from '@mui/material';
import { useRouter } from 'next/router';
import { VHPDataPublisher, VHPDataRetriever, ComeFrom } from '@care/mfe-data-transfer-vhp';
import { useEffect } from 'react';
import { CLIENT_FEATURE_FLAGS, TOP_NAV_LINKS, VERTICALS_NAMES } from '@/constants';
import useTestFlags from '@/components/hooks/useTestFlags';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { AnalyticsEventName, CtaLocation, CtaText, ScreenName } from '@/types/enums';

import { SxClassProps } from '@/types';
import { applyToJobsHandler, handleListYourBusinessClick, logInHandler } from './utils';

const classes: SxClassProps = {
  link: (theme) => ({
    marginRight: theme.spacing(3),
    color: theme.palette.care?.navy[900],
    cursor: 'pointer', // needed for links without href
    '&:hover': {
      color: theme.palette.care?.navy[600],
    },
    '&:focus': {
      color: theme.palette.care?.navy[600],
    },
  }),
  joinNowBtn: (theme) => ({
    [theme.breakpoints.up('sm')]: {
      maxWidth: '144px',
    },
  }),
};

type TopNavigationDWProps = {
  verticalsView: boolean;
  isZipCodeLayoutView?: boolean;
  toggleModal?: () => void;
};

function TopNavigationDW({
  verticalsView,
  isZipCodeLayoutView,
  toggleModal,
}: TopNavigationDWProps) {
  const router = useRouter();
  const { applyToJobsModal, vhpTriageProviderVertical, vhpAllListYourBusinessCTA } = useTestFlags();
  const routeProviderToTriagePage = vhpTriageProviderVertical?.value === 2;
  const showListYourBusinessCTA = vhpAllListYourBusinessCTA?.value === 2;
  const VHPPublisher = new VHPDataPublisher();
  const VHPRetriever = new VHPDataRetriever();
  const existingData = VHPRetriever.retrieveData();

  useEffect(() => {
    AnalyticsHelper.logTestExposure(
      CLIENT_FEATURE_FLAGS.GROWTH_VHP_ALL_LIST_YOUR_BUSINESS_CTA,
      vhpAllListYourBusinessCTA
    );
  }, [vhpAllListYourBusinessCTA]);

  const loginClickHandler = () => {
    logInHandler();
  };

  const onApplyToJobsClick = () => {
    if (applyToJobsModal?.value && toggleModal) {
      return toggleModal();
    }
    const data = { ...existingData, comeFrom: ComeFrom.VHP };

    VHPPublisher.publishData(data);
    return applyToJobsHandler(false, undefined, routeProviderToTriagePage);
  };

  const handleJoinNow = () => {
    const data = { ...existingData, comeFrom: ComeFrom.VHP };

    VHPPublisher.publishData(data);

    AnalyticsHelper.logEvent({
      name: AnalyticsEventName.CTA_INTERACTED_VHP,
      data: {
        screen_name: ScreenName.VISITOR_HOMEPAGE,
        enrollment_initiated: true,
        cta_text: CtaText.JOIN_NOW,
        cta_location: CtaLocation.VHP_HEADER,
        vertical: VERTICALS_NAMES.unknown,
      },
    });

    router.push(TOP_NAV_LINKS.joinNowVhpMfe);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}>
      {showListYourBusinessCTA && (
        <Link
          underline="none"
          onClick={(e) => handleListYourBusinessClick(e, 'VHP Header')}
          variant="body1"
          color="textPrimary"
          sx={classes.link}>
          List your business
        </Link>
      )}
      {verticalsView && (
        <Link
          underline="none"
          onClick={onApplyToJobsClick}
          variant="body1"
          color="textPrimary"
          sx={classes.link}>
          Apply to jobs
        </Link>
      )}
      <Link
        underline="none"
        onClick={loginClickHandler}
        variant="body1"
        color="textPrimary"
        sx={classes.link}>
        Log in
      </Link>
      <Button
        color="primary"
        variant={isZipCodeLayoutView ? 'contained' : 'outlined'}
        size="medium"
        sx={classes.joinNowBtn}
        onClick={handleJoinNow}>
        Join now
      </Button>
    </Box>
  );
}

TopNavigationDW.defaultProps = {
  isZipCodeLayoutView: false,
};

export default TopNavigationDW;
