import React from 'react';
import { Box } from '@mui/material';
import useResponsive from '@/components/hooks/useResponsive';
import { SxClassProps } from '@/types';

export enum POSITION {
  RELATIVE = 'relative',
  ABSOLUTE = 'absolute',
}

interface StyleProps {
  height?: number;
  position?: POSITION;
  backgroundColor?: string;
  attachToBottom?: boolean;
  flipVertical?: boolean;
}

const getClasses = (styleProps: StyleProps): SxClassProps => ({
  wave: {
    position: styleProps.position,
    width: '100%',
    height: `${styleProps.height}px`,
    backgroundColor: styleProps.backgroundColor,
    bottom: styleProps?.attachToBottom ? '0' : 'auto',
    '& svg': {
      position: 'absolute',
      top: '0',
      display: 'block',
      width: '100%',
      height: `${styleProps.height}px`,
      transform: styleProps.flipVertical ? 'rotateX(180deg)' : 'inherit',
    },
  },
});

type WaveDividerProps = {
  position?: POSITION;
  shapeFill?: string;
  backgroundColor?: string;
  flipVertical?: boolean;
  attachToBottom?: boolean;
};

function WaveDivider({
  position,
  shapeFill,
  attachToBottom,
  backgroundColor,
  flipVertical,
}: WaveDividerProps) {
  const { isMobile, isTablet } = useResponsive();

  const width = isMobile ? 1000 : 1440;
  let height = isMobile ? 35 : 85;
  if (isTablet) {
    height = 55;
  }

  const classes = getClasses({
    backgroundColor: flipVertical ? shapeFill : backgroundColor,
    height,
    position,
    flipVertical,
    attachToBottom,
  });

  return (
    <Box sx={classes.wave}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 1440 85"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none">
        <path
          d="M7.89135e-06 40.2672C7.89135e-06 40.2672 222.113 -19.0729 720 40.2671C1293.5 108.619 1440 0.000606533 1440 0.000606533L1440 179.884L1.57259e-05 179.884L7.89135e-06 40.2672Z"
          fill={flipVertical ? backgroundColor : shapeFill}
        />
      </svg>
    </Box>
  );
}

WaveDivider.defaultProps = {
  position: POSITION.RELATIVE,
  shapeFill: '#000000',
  backgroundColor: 'transparent',
  flipVertical: false,
  attachToBottom: false,
};

export default WaveDivider;
