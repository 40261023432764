import React from 'react';
import { Box } from '@mui/material';
import { SxClassProps } from '@/types';

const classes: SxClassProps = {
  promoRibbon: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3, 2),
    background: theme.palette.care?.latte[100],
  }),
  promoRibbonText: (theme) => ({
    fontWeight: 700,
    fontSize: '0.88rem',
    lineHeight: '1.2rem',
    color: theme.palette.care?.grey[900],
    [theme.breakpoints.up('md')]: {
      fontSize: '1.15rem',
      lineHeight: '1.5rem',
    },
  }),
};

type PromocodeRibbonProps = {
  discountMessage: string;
};

const PromocodeRibbon = (props: PromocodeRibbonProps) => {
  const { discountMessage } = props;

  return (
    <Box data-testid="promocode-ribbon" sx={classes.promoRibbon}>
      <Box component="span" sx={classes.promoRibbonText}>
        {discountMessage}
      </Box>
    </Box>
  );
};

export default PromocodeRibbon;
