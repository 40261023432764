import React, { useEffect, useRef } from 'react';
import { useTheme, Box } from '@mui/material';
import { Layout, LayoutVariant, SxClassProps } from '@/types';
import { LAYOUT_VARIANTS, LAYOUTS } from '@/constants';
import useResponsive from '@/components/hooks/useResponsive';
import WaveDivider, { POSITION } from '../WaveDivider/WaveDivider';

const getLogoBlockClass = (isDesktop: boolean): SxClassProps => ({
  logoBlock: (theme) => ({
    display: 'block',
    width: '300%',
    height: '31px',
    position: 'absolute',
    overflow: 'hidden',
    '&:hover': {
      animationPlayState: 'paused',
    },
    '& img': {
      paddingRight: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.5),
      display: 'inline-block',
    },
    ...(!isDesktop && {
      animation: 'marquee',
      '@keyframes marquee': {
        '0%': {
          transform: 'translate(0%, 0)',
        },
        '100%': {
          transform: 'translate(-33.333333%, 0)',
        },
      },
      animationDuration: '10s',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
    }),
  }),
});

const getClasses = (layout?: Layout, layoutVariant?: LayoutVariant): SxClassProps => ({
  marqueeLogos: [
    (theme) => ({
      width: '100%',
      overflow: 'hidden',
      background: theme.palette.grey[100],
      height: '75px',
      ...(layoutVariant &&
        layout === LAYOUTS.valueDrivers && {
          [theme.breakpoints.down('sm')]: {
            height: '64px',
            '& div': {
              paddingTop: theme.spacing(0),
            },
          },
        }),
      ...(layoutVariant &&
        layout === LAYOUTS.valueDrivers &&
        layoutVariant === LAYOUT_VARIANTS.triageTabs && {
          background: 'transparent',
          marginBottom: '40px',
          [theme.breakpoints.down('lg')]: {
            marginTop: '14px',
          },
        }),
      ...(layoutVariant &&
        layout === LAYOUTS.valueDrivers &&
        layoutVariant === LAYOUT_VARIANTS.triageModal && {
          background: theme.palette.care?.latte[100],
          marginBottom: undefined,
          [theme.breakpoints.down('lg')]: {
            marginTop: undefined,
          },
        }),
      ...(layout === LAYOUTS.zipCode && {
        background: theme.palette.care?.grey[50],
        borderBottom: `1px solid ${theme.palette.care?.grey[300]}`,
        [theme.breakpoints.down('sm')]: {
          height: '64px',
          '& div': {
            paddingTop: theme.spacing(0),
          },
        },
      }),
    }),
  ],
  marquee: (theme) => ({
    height: '75px',
    width: '970px',
    paddingTop: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '908px',
    },
    [theme.breakpoints.up('lg')]: {
      width: layout === LAYOUTS.valueDrivers ? '1000px' : '970px',
    },
  }),
  logoItem: {
    float: layout === LAYOUTS.valueDrivers ? 'none' : 'left',
    width: '33.333333%',
    overflow: 'hidden',
  },
  valueDriversDesktop: (theme) => ({
    height: '50px',
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.care?.latte[100],
  }),
  valueDriversCredibility: {
    height: '27px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'self-end',
    columnGap: '32px',
  },
  valueDriversPrefixText: (theme) => ({
    display: 'none',
    color: theme.palette.care?.grey[700],
    fontWeight: 700,
    fontSize: '12px',
    paddingRight: '20px',
    position: 'relative',
    bottom: '5px',
    [theme.breakpoints.down('xl')]: {
      paddingLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      display: 'initial',
    },
  }),
  valueDriversLogoBar: (theme) => ({
    height: '27px',
    width: '700px',
    [theme.breakpoints.up('lg')]: {
      height: '27px',
      width: '852px',
    },
  }),
  waveSection: {
    position: 'relative',
  },
});

type NewsScrollProps = {
  layout?: Layout;
  layoutVariant?: LayoutVariant;
  credibilityBarLogo: string;
};

function NewsScroll({ layout, layoutVariant, credibilityBarLogo }: NewsScrollProps) {
  const isValueDriversLayout = layout === LAYOUTS.valueDrivers;
  const { isDesktop } = useResponsive();
  const theme = useTheme();

  const classes = getClasses(layout, layoutVariant);
  const logoBlockClass = getLogoBlockClass(isDesktop);

  const spanElement1 = useRef<HTMLSpanElement>(null);
  const spanElement2 = useRef<HTMLSpanElement>(null);
  const spanElement3 = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    [spanElement1, spanElement2, spanElement3].forEach((el) => {
      const img = new Image();
      img.src = credibilityBarLogo;
      img.alt = 'News';
      img.height = 31;
      img.width = 970;

      if (el && el.current) {
        el.current.appendChild(img);
      }
    });
  }, []);

  return (
    <>
      {isValueDriversLayout && layoutVariant === LAYOUT_VARIANTS.triageModal && (
        <Box component="section" sx={classes.waveSection}>
          <WaveDivider
            position={POSITION.ABSOLUTE}
            backgroundColor="transparent"
            shapeFill={theme.palette.care?.latte[100]}
            attachToBottom
          />
        </Box>
      )}
      <Box component="section" sx={classes.marqueeLogos}>
        <Box sx={classes.marquee}>
          <Box sx={logoBlockClass.logoBlock}>
            {isValueDriversLayout && isDesktop && (
              <Box component="span" sx={classes.valueDriversPrefixText}>
                Featured in
              </Box>
            )}
            <Box component="span" sx={classes.logoItem} ref={spanElement1} />
            <Box component="span" sx={classes.logoItem} ref={spanElement2} />
            <Box component="span" sx={classes.logoItem} ref={spanElement3} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default NewsScroll;
