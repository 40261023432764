import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  GetReferrerDetails,
  // eslint-disable-next-line camelcase
  GetReferrerDetails_getReferrerDetails,
  GetReferrerDetailsVariables,
} from '@/__generated__/GetReferrerDetails';
import { GET_REFERRER_DETAILS } from '@/components/request/GQL';
import { useAppState } from '@/components/contexts/AppState';
import logger from '@/lib/clientLogger';

const useRxCodes = () => {
  const { referrerCookie } = useAppState();
  const [rxData, setRxData] = useState<
    // eslint-disable-next-line camelcase
    Partial<GetReferrerDetails_getReferrerDetails> | undefined
  >(undefined);

  const [rxDataDoneLoading, setRxDataDoneLoading] = useState<boolean>(false);
  const [getRxCodes, { data: rxResponse, error: rxFetchError }] = useLazyQuery<
    GetReferrerDetails,
    GetReferrerDetailsVariables
  >(GET_REFERRER_DETAILS);

  useEffect(() => {
    let callingQuery = false;
    if (referrerCookie && getRxCodes) {
      const referrerParts = referrerCookie.split(',');
      if (referrerParts.length > 0) {
        callingQuery = true;
        const referrerId = referrerParts[0];
        getRxCodes({ variables: { referrerId } });
      }
    }
    if (!callingQuery) {
      setRxDataDoneLoading(true);
    }
  }, [referrerCookie, getRxCodes]);

  useEffect(() => {
    if (rxFetchError && !rxDataDoneLoading) {
      logger.error({ event: 'vhpGetReferrerDetails', graphQLError: rxFetchError.message });
      setRxDataDoneLoading(true);
    }
  }, [rxFetchError, rxDataDoneLoading]);

  useEffect(() => {
    if (rxResponse && !rxDataDoneLoading) {
      // eslint-disable-next-line camelcase
      const tempRxData: Partial<GetReferrerDetails_getReferrerDetails> = {
        ...rxResponse.getReferrerDetails,
      };
      // remove these fields from being passed to Amplitude event data
      delete tempRxData.id;
      delete tempRxData.__typename;

      type dataKeysType = keyof typeof tempRxData;
      const dataKeys: dataKeysType[] = Object.keys(tempRxData) as dataKeysType[];

      // exclude the null rx values from the amplitude event
      dataKeys.forEach((key: keyof typeof tempRxData) => {
        if (tempRxData[key] === null) {
          delete tempRxData[key];
        }
      });

      setRxData(tempRxData);
      setRxDataDoneLoading(true);
    }
  }, [rxResponse, rxDataDoneLoading]);

  return { rxData, rxDataDoneLoading };
};

export default useRxCodes;
