/* eslint-disable react/require-default-props */
import { FC, useState, useEffect, ChangeEvent, useRef, KeyboardEvent } from 'react';
import { Button, FormGroup, Grid, InputAdornment, Box } from '@mui/material';
import { TextField, InlineTextField } from '@care/react-component-lib';

import { Icon24InfoLocation } from '@care/react-icons';
import { Location, SxClassProps } from '@/types';
import { validPartialZipCodeRegex, validZipCodeRegex } from '@/utilities/zipCodeValidators';
import useZipLocation from '@/components/hooks/useZipLocation';

interface ZipInputProps {
  location?: Location;
  showLabel?: boolean;
  showLocationIcon?: boolean;
  prepopulateZip?: boolean;
  onChange: (location: Location) => void;
  onError?: (error: boolean) => void;
  setValidateOnClick?: (arg: boolean) => void;
  validateOnLostFocusOrClick?: boolean;
  setShouldGoNext?: (arg: boolean) => void;
  validateOnClick?: boolean;
  sx?: [SxClassProps];
  withIntegratedCta?: boolean;
  iconLeft?: boolean;
  handleZipCodeButtonClick?: () => void;
}

const getClasses = (showLabel: boolean, withIntegratedCta: boolean): SxClassProps => ({
  inlineTextFieldOverride: [
    {
      '& .MuiInputBase-root': {
        height: showLabel ? '64px' : '46px',
      },
    },
    withIntegratedCta && {
      width: {
        xs: '62%',
        sm: '54%',
        md: '70%',
      },

      '& .MuiInput-root': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  ],
  textFieldOverride: [
    {
      paddingLeft: 0,
      paddingRight: 0,
      '& .MuiInputBase-root': {
        height: showLabel ? '64px' : '46px',
        width: '100%',
      },
      '& .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart': {
        padding: '0',
      },
    },
  ],
  buttonContainer: {
    marginTop: '22px',
    width: {
      xs: '33%',
      sm: '40%',
      md: '28%',
    },
  },
  buttonOverride: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: '12px',
    borderBottomRightRadius: '12px',
    height: '64px',
    width: '100%',
  },
  formGroupOverride: {
    width: '100%',
  },
});

const ZipInput: FC<ZipInputProps> = ({
  location,
  showLabel = true,
  showLocationIcon = true,
  validateOnLostFocusOrClick = false,
  validateOnClick = false,
  prepopulateZip = false,
  setValidateOnClick = () => null,
  setShouldGoNext = () => null,
  withIntegratedCta = false,
  handleZipCodeButtonClick = () => null,
  onChange,
  onError,
  sx = [],
  iconLeft = false,
}) => {
  const [shouldValidate, setShouldValidate] = useState(false);
  const textInput = useRef<HTMLInputElement>(null);
  const classes = getClasses(showLabel, withIntegratedCta);
  const { zipComponentState, loading, validateZipCode, setZipcodeHook } = useZipLocation({
    initialZipcode: location?.inputZipcode ?? '',
    validateOnLostFocusOrClick,
    populateZipOnPageEnter: prepopulateZip,
  });
  const { zipcode, city, state, helperText, error, verifiedApiZipcode } = zipComponentState;

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShouldGoNext(false);
    setValidateOnClick(false);
    const newZipcode = e.target.value;

    if (!validPartialZipCodeRegex.test(newZipcode)) {
      return;
    }
    setZipcodeHook(newZipcode);
  };

  useEffect(() => {
    validateZipCode(validateOnLostFocusOrClick);
  }, [zipcode]);

  useEffect(() => {
    if (onError) {
      onError(error || loading);
    }
  }, [error, loading]);

  useEffect(() => {
    onChange({
      inputZipcode: zipcode,
      verifiedApiZipcode: verifiedApiZipcode ?? null,
      city,
      state,
      loading,
    });
  }, [zipcode, verifiedApiZipcode, city, state, loading]);

  useEffect(() => {
    if (!validateOnLostFocusOrClick) {
      return;
    }
    if (textInput && textInput.current) {
      textInput.current.onblur = () => setShouldValidate(true);
      textInput.current.onfocus = () => setShouldValidate(false);
    }
  }, []);

  const blockNonNumericInput = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const showValidationResult = (): { message: string; validationError: boolean } => {
    const isLoading = () => loading && { message: '', validationError: false };

    const defaultValidation = () =>
      !validateOnLostFocusOrClick && {
        message: zipcode ? helperText : '',
        validationError: Boolean(error && zipcode),
      };

    const validateOnCompleteZipcode = () =>
      validZipCodeRegex.test(zipcode) && { message: helperText, validationError: error };

    const validateOnButtonClick = () =>
      validateOnClick && { message: helperText, validationError: error };

    const onLostFocusValidation = () => ({
      message: shouldValidate ? helperText : '',
      validationError: shouldValidate && error,
    });

    return (
      isLoading() ||
      defaultValidation() ||
      validateOnCompleteZipcode() ||
      validateOnButtonClick() ||
      onLostFocusValidation()
    );
  };

  const { message } = showValidationResult();

  const commonTextFieldProps = {
    inputRef: textInput,
    id: 'zipCode',
    name: 'zipCode',
    helperText: message,
    value: zipcode,
    onChange: onInputChange,
    onKeyPress: blockNonNumericInput,
    autoFocus: false,
    autoComplete: 'off',
    tabIndex: -1,
  };

  const renderInputField = () => {
    return iconLeft ? (
      <TextField
        {...commonTextFieldProps}
        placeholder="ZIP code"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {showLocationIcon && <Icon24InfoLocation />}
            </InputAdornment>
          ),
          inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
        }}
        sx={classes.textFieldOverride}
        fullWidth
      />
    ) : (
      <InlineTextField
        {...commonTextFieldProps}
        label={showLabel && 'ZIP code'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {showLocationIcon && <Icon24InfoLocation />}
            </InputAdornment>
          ),
          inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
        }}
        sx={classes.inlineTextFieldOverride}
      />
    );
  };

  return (
    <Grid container item xs={12} direction="row" sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {withIntegratedCta ? (
        <FormGroup row sx={classes.formGroupOverride}>
          {renderInputField()}
          <Box sx={classes.buttonContainer}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleZipCodeButtonClick}
              sx={classes.buttonOverride}>
              Search
            </Button>
          </Box>
        </FormGroup>
      ) : (
        renderInputField()
      )}
    </Grid>
  );
};

export default ZipInput;
