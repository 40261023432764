import React from 'react';
import { Icon24UtilityClose } from '@care/react-icons';
import { InternalLinkingMenu } from '@care/navigation';
import { Box } from '@mui/material';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { SxClassProps } from '@/types';

interface HamburgerMenuProps {
  onClose?: Function;
  isMenuOpen?: Boolean;
}

const rootClass: SxClassProps = {
  root: (theme) => ({
    overflowY: 'auto',
    left: 0,
    top: 0,
    bottom: 0,
    width: '340px',
    padding: theme.spacing(6, 0),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.care.white,
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.15)',
    zIndex: theme.zIndex.drawer,

    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      padding: theme.spacing(3, 0),
      borderRadius: '0 0 16px 16px',
      top: '100%',
      left: '50%',
      bottom: 'auto',
      transform: `translate(calc(-100% + 42px), 0)`,
    },

    [theme.breakpoints.down('lg')]: {
      position: 'fixed',
    },
  }),
};

const getClasses = (isMenuOpen: false | Boolean): SxClassProps => ({
  root: rootClass,
  hamburger: [
    Array.isArray(rootClass.root) ? rootClass.root[0] : rootClass.root,
    isMenuOpen
      ? {
          transform: 'none',
          transition: 'transform .33s cubic-bezier(0,0,.3,1)',
        }
      : {
          transform: 'translateX(-101%)',
          willChange: 'transform',
          transition: 'transform .15s cubic-bezier(0,0,.3,1)',
        },
  ],
  hiddenMenu: {
    transform: 'translateX(-101%)',
    willChange: 'transform',
    transition: 'transform .15s cubic-bezier(0,0,.3,1)',
  },
  animatedHeaderMenu: {
    transform: 'none',
    transition: 'transform .33s cubic-bezier(0,0,.3,1)',
  },
  closeButton: (theme) => ({
    backgroundColor: theme.palette.care.white,
    display: 'none',
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    cursor: 'pointer',
    zIndex: theme.zIndex.tooltip,

    '& > svg': {
      height: theme.spacing(3),
      marginTop: 0,
      width: theme.spacing(3),
    },

    [theme.breakpoints.down('lg')]: {
      display: 'flex',
    },
  }),
});

const HamburgerMenu = (props: HamburgerMenuProps) => {
  const { onClose = () => {}, isMenuOpen = false } = props;
  const classes = getClasses(isMenuOpen);

  const handleHamburgerButtonClick = (eventData: Object) => {
    AnalyticsHelper.logEvent({
      name: 'CTA Interacted',
      data: {
        cta_clicked: 'Internal linking menu',
        cta_location: 'VHP Header',
        ...eventData,
      },
    });
  };

  return (
    <Box sx={classes.hamburger}>
      <Box
        data-testid="Header-menu-close-button"
        role="button"
        tabIndex={0}
        sx={classes.closeButton}
        onClick={() => onClose()}
        onKeyPress={() => onClose()}>
        <Icon24UtilityClose />
      </Box>

      <InternalLinkingMenu
        isContainingMenuOpen={isMenuOpen.valueOf()}
        logAnalyticsEvent={handleHamburgerButtonClick}
        renderForMW
      />
    </Box>
  );
};

export default HamburgerMenu;
