import React from 'react';
import { IconProps } from '@/types/icon';

function PetCareIcon({ width, height }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.4118 17.7742C38.1999 17.732 37.9899 17.7042 37.7822 17.6894C37.2819 17.6539 36.8864 17.2767 36.8652 16.789C36.8642 16.7652 36.8629 16.741 36.8619 16.7172C36.7553 14.6694 36.0086 12.5162 34.8136 10.8097C33.2993 8.64701 31.2307 7.46148 29.1339 7.56926C28.1647 7.61731 27.2989 7.91908 26.5438 8.4126C25.4541 9.12482 24.0427 9.12482 22.9535 8.4126C22.1984 7.91908 21.333 7.61731 20.3633 7.56926C18.2689 7.46732 16.1984 8.64701 14.6841 10.8093C13.4892 12.5162 12.7424 14.669 12.6358 16.7167C12.6344 16.741 12.6335 16.7656 12.6321 16.7899C12.6109 17.2776 12.2158 17.6548 11.7155 17.6898C11.5078 17.7042 11.2978 17.732 11.0859 17.7742C9.14886 18.1604 7.54316 19.6823 6.68009 21.9505C6.00578 23.7235 5.84009 25.7909 6.22547 27.6231C6.62193 29.5074 7.55147 31.2053 8.84332 32.4048C9.46732 32.9841 10.1587 33.4291 10.8842 33.7309C12.1105 34.2405 13.0438 35.2141 13.4984 36.4342C14.2853 38.5444 16.1199 40.2086 18.6173 40.8723C19.2944 41.0524 19.9899 41.1409 20.6813 41.1409C21.9709 41.1409 23.2461 40.8328 24.3575 40.2329L24.3612 40.2306C24.5906 40.1071 24.8707 40.1022 25.0992 40.2266C26.8498 41.1786 29.0292 41.4013 31.0816 40.8108C31.473 40.699 31.8598 40.558 32.2313 40.3923C34.0433 39.5853 35.3721 38.1312 36.0085 36.4005C36.4456 35.2119 37.3539 34.2446 38.5521 33.7596C40.8039 32.8484 42.6482 30.5865 43.2722 27.6231C44.1205 23.5919 42.3335 18.5556 38.4118 17.7742Z"
        fill="#F3DEDC"
      />
      <path
        d="M40.3501 27.0375C39.7801 29.7462 37.7516 31.63 35.8192 31.2447C33.8872 30.8599 32.7832 28.3519 33.3532 25.6431C33.9232 22.9344 35.8819 20.2296 37.8139 20.6149C39.7459 20.9998 40.9201 24.3287 40.3501 27.0375Z"
        fill="#E84E3A"
      />
      <path
        d="M9.14922 27.0375C9.71922 29.7462 11.7477 31.63 13.6801 31.2447C15.6121 30.8599 16.7161 28.3519 16.1461 25.6431C15.5761 22.9344 13.6174 20.2296 11.6854 20.6149C9.75338 20.9998 8.57922 24.3287 9.14922 27.0375Z"
        fill="#E84E3A"
      />
      <path
        d="M33.8836 16.8669C34.0479 20.0175 32.3961 22.6594 30.1941 22.7681C27.9921 22.8767 26.0739 20.4109 25.9101 17.2603C25.7457 14.1096 27.0851 10.58 29.2871 10.4713C31.4891 10.3626 33.7197 13.7163 33.8841 16.8664L33.8836 16.8669Z"
        fill="#E84E3A"
      />
      <path
        d="M15.6167 16.8669C15.4524 20.0175 17.1043 22.6594 19.3063 22.7681C21.5083 22.8767 23.4264 20.4109 23.5903 17.2603C23.7546 14.1096 22.4152 10.58 20.2132 10.4713C18.0112 10.3626 15.7806 13.7163 15.6163 16.8664L15.6167 16.8669Z"
        fill="#E84E3A"
      />
      <path
        d="M19.4046 38.075C20.5616 38.3826 21.8406 38.2749 22.9141 37.696C23.0562 37.6192 23.1915 37.5308 23.3198 37.4338C24.1755 36.7885 25.2961 36.8401 26.1707 37.4567C27.3402 38.2812 28.8726 38.4226 30.2396 38.0297C30.4949 37.9565 30.7459 37.8653 30.991 37.7558C32.5575 37.0584 33.6776 35.4709 33.4113 33.486C33.1159 31.2829 31.7595 29.325 30.4422 27.7699C29.9516 27.1906 29.2381 26.4968 28.4807 25.8133C26.3332 23.8747 23.1689 23.8747 21.0209 25.8133C20.2635 26.4968 19.5495 27.1906 19.0593 27.7699C17.7426 29.325 16.3856 31.2825 16.0902 33.486C15.7612 35.942 17.4416 37.5532 19.4046 38.075Z"
        fill="#E84E3A"
      />
    </svg>
  );
}

PetCareIcon.defaultProps = {
  width: '48',
  height: '48',
};

export default PetCareIcon;
