import { Dispatch, SetStateAction } from 'react';
import getConfig from 'next/config';
import { Box, Typography } from '@mui/material';
import VERTICALS_INFO from '@/components/shared/VerticalsInfoValueDriverLayout';
import {
  SEEKER_ENROLL,
  VERTICALS,
  VERTICALS_NAMES,
  VerticalSelection as TVerticalSelection,
  LAYOUT_VARIANTS,
} from '@/constants';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { LayoutVariant, SxClassProps } from '@/types';

const {
  publicRuntimeConfig: { CZEN_GENERAL },
} = getConfig();

const getClasses = (layout: LayoutVariant): SxClassProps => ({
  container: [
    (theme) => ({
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '10px 8px',
      padding: theme.spacing(3, 0, 0),

      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3, 0, 1),
      },

      [theme.breakpoints.up('md')]: {
        gap: '8px',
        padding: theme.spacing(4.25, 0, 1),
      },
    }),
    layout === LAYOUT_VARIANTS.triageTabs &&
      ((theme) => ({
        backgroundColor: theme.palette.care?.latte[100],
        padding: theme.spacing(2, 1.75),

        [theme.breakpoints.up('sm')]: {
          gap: '8px',
          justifyContent: 'center',
        },

        [theme.breakpoints.up('md')]: {
          gap: '30.5px',
          padding: theme.spacing(0, 0, 0),
        },

        [theme.breakpoints.up('lg')]: {
          gap: '40px',
        },
      })),
  ],
  item: [
    (theme) => ({
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '2px 0',
      borderRadius: theme.spacing(1),
      boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
      color: theme.palette.care?.grey[600],
      background: theme.palette.care?.white,
      cursor: 'pointer',
      width: 'calc(33% - 5px)',
      height: '96px',
      '&:hover': {
        boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.25)',
      },

      [theme.breakpoints.up('sm')]: {
        padding: '12px',
        width: '120px',
      },

      [theme.breakpoints.up('md')]: {
        gap: '4px',
        padding: theme.spacing(1.5),
        width: '108px',
      },
    }),
    layout === LAYOUT_VARIANTS.triageTabs &&
      ((theme) => ({
        [theme.breakpoints.up('sm')]: {
          width: '108px',
        },

        [theme.breakpoints.up('md')]: {
          width: '130px',
          height: '110px',
        },

        [theme.breakpoints.up('lg')]: {
          width: '180px',
          height: '130px',
        },
      })),
  ],
  label: [
    (theme) => ({
      lineHeight: '1rem',
      marginBottom: '8px',
      fontSize: '14px',

      [theme.breakpoints.up('md')]: {
        lineHeight: 'inherit',
        marginBottom: '0',
      },
    }),
    layout === LAYOUT_VARIANTS.triageTabs &&
      ((theme) => ({
        [theme.breakpoints.up('lg')]: {
          fontSize: '16px',
        },
      })),
  ],
});

type VerticalSelectionProps = {
  setSelectedVertical: Function;
  setOpenModalOrDrawer: Dispatch<SetStateAction<boolean>>;
  layoutVariant: LayoutVariant;
};

function VerticalSelection({
  setSelectedVertical,
  setOpenModalOrDrawer,
  layoutVariant,
}: VerticalSelectionProps) {
  const verticalsInfo = VERTICALS_INFO({
    isDaycareRemoved: true,
  });
  const classes = getClasses(layoutVariant);

  const handleVerticalSelection = (vertical: TVerticalSelection) => {
    AnalyticsHelper.logEvent({
      name: 'CTA Interacted',
      data: {
        cta_location: layoutVariant === LAYOUT_VARIANTS.triageTabs ? 'VHP Body' : 'VHP Hero',
        vertical: VERTICALS_NAMES[vertical],
      },
    });
    /* istanbul ignore if */
    if (vertical === VERTICALS.DAY_CARE) {
      window.location.assign(`${CZEN_GENERAL}${SEEKER_ENROLL[VERTICALS.DAY_CARE]}`);
    } else {
      setSelectedVertical(vertical);
      setOpenModalOrDrawer(true);
    }
  };

  return (
    <Box sx={classes.container} data-testid="vertical-selection">
      {verticalsInfo.map((option, index) => (
        <Box
          key={option.vertical}
          tabIndex={index}
          role="button"
          sx={classes.item}
          data-testid={option.vertical}
          onClick={() => handleVerticalSelection(option.vertical)}
          onKeyDown={() => handleVerticalSelection(option.vertical)}>
          {option.valueDriversIcon}
          <Typography variant="body2" sx={classes.label}>
            {option.displayName}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

export default VerticalSelection;
