import React from 'react';
import { IconProps } from '@/types/icon';

function HousekeepingIcon({ width, height }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.9069 38.4682H12.2315C11.3896 38.4682 10.707 37.804 10.707 36.985V20.5097L25.0692 9.73438L39.4313 20.5097V36.985C39.4313 37.804 38.7487 38.4682 37.9069 38.4682Z"
        fill="#F3DEDC"
      />
      <path
        d="M40.7413 22.1681C40.3998 22.1681 40.0555 22.0603 39.7665 21.8384L26.2333 11.4332C25.5507 10.9082 24.587 10.9082 23.9039 11.4332L10.3707 21.8384C9.68854 22.3625 8.69946 22.2498 8.16039 21.5861C7.62131 20.9228 7.73762 19.96 8.41931 19.4355L24.0927 7.38441C24.6645 6.94478 25.4722 6.94478 26.0441 7.38441L41.7175 19.4355C42.3992 19.96 42.5155 20.9228 41.9764 21.5861C41.6658 21.9687 41.2061 22.1681 40.7413 22.1681Z"
        fill="#E84E3A"
      />
      <path
        d="M17.5933 23.3594H21.2672C22.457 23.3594 23.423 24.2993 23.423 25.457V38.4655H15.4375V25.457C15.4375 24.2993 16.4035 23.3594 17.5933 23.3594Z"
        fill="#E84E3A"
      />
      <path
        d="M29.5494 23.3594H33.2232C34.4131 23.3594 35.3791 24.2993 35.3791 25.457V30.9122H27.3936V25.457C27.3936 24.2993 28.3596 23.3594 29.5494 23.3594Z"
        fill="#E84E3A"
      />
      <path
        d="M35.4976 30.2969H27.2749C26.7579 30.2969 26.3389 30.7046 26.3389 31.2076C26.3389 31.7105 26.7579 32.1183 27.2749 32.1183H35.4976C36.0146 32.1183 36.4336 31.7105 36.4336 31.2076C36.4336 30.7046 36.0146 30.2969 35.4976 30.2969Z"
        fill="#E84E3A"
      />
      <path
        d="M8.60536 28.033C8.65706 27.8848 8.87213 27.8848 8.92382 28.033C9.37059 29.3196 10.4091 30.33 11.7314 30.7647C11.8837 30.815 11.8837 31.0242 11.7314 31.0745C10.4091 31.5092 9.37059 32.5196 8.92382 33.8062C8.87213 33.9544 8.65706 33.9544 8.60536 33.8062C8.15859 32.5196 7.12013 31.5092 5.79782 31.0745C5.64552 31.0242 5.64552 30.815 5.79782 30.7647C7.12013 30.33 8.15859 29.3196 8.60536 28.033Z"
        fill="#E84E3A"
      />
      <path
        d="M36.2743 5.65021C36.326 5.50201 36.5411 5.50201 36.5928 5.65021C37.0395 6.93678 38.078 7.94717 39.4003 8.38186C39.5526 8.43216 39.5526 8.64142 39.4003 8.69172C38.078 9.12641 37.0395 10.1368 36.5928 11.4234C36.5411 11.5716 36.326 11.5716 36.2743 11.4234C35.8275 10.1368 34.7891 9.12641 33.4668 8.69172C33.3145 8.64142 33.3145 8.43216 33.4668 8.38186C34.7891 7.94717 35.8275 6.93678 36.2743 5.65021Z"
        fill="#E84E3A"
      />
    </svg>
  );
}

HousekeepingIcon.defaultProps = {
  width: '48',
  height: '48',
};

export default HousekeepingIcon;
