export enum CtaLocation {
  VHP_MODAL = 'VHP Modal',
  VHP_HERO = 'VHP Hero',
  VHP_BODY = 'VHP Body',
  VHP_HEADER = 'VHP Header',
}

export enum ScreenName {
  VISITOR_HOMEPAGE = 'Visitor Homepage',
}

export enum CtaText {
  SEARCH = 'Search',
  GET_STARTED = 'Get started',
  TRUST_AND_SAFETY_CENTER = 'trust and safety center',
  JOIN_NOW = 'Join now',
  LOG_IN = 'Log in',
  APPLY_TO_JOBS = 'Apply to jobs',
  LIST_YOUR_BUSINESS = 'List your business',
  INTERNAL_LINKING_MENU = 'Internal linking menu',
}

export enum AnalyticsEventName {
  CTA_INTERACTED_VHP = 'CTA Interacted - VHP',
  MEMBER_ENROLLED = 'Member Enrolled',
  TEST_EXPOSURE = 'Test Exposure',
  HOMEPAGE_VIEWED = 'Homepage Viewed',
}
