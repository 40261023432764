import React, { ComponentType, useEffect, useRef, useState } from 'react';
import useOnScreen from '@/components/hooks/useOnScreen';

export interface WithLazyLoadProps {
  hasLoaded?: boolean;
}

// TODO: consider adding LoaderComponent param instead of passing `hasLoaded` to WrapperComponent?

const withLazyLoad = <P extends object>(
  WrappedComponent: ComponentType<P>,
  threshold = 0.1
): React.FC<P> => {
  const Wrapper = (props: P) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isInView] = useOnScreen(ref, threshold);
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
      if (isInView && !hasLoaded) {
        setHasLoaded(true);
      }
    }, [isInView, hasLoaded]);

    return (
      <div ref={ref}>
        {/*
         * NOTE: WrappedComponent is responsible for rendering the placeholder loading state using hasLoaded prop
         * If there is no placeholder in the WrappedComponent then the component will render as normal i.e. not lazy loaded
         */}
        <WrappedComponent {...props} hasLoaded={hasLoaded} />
      </div>
    );
  };

  Wrapper.displayName = `withLazyLoad${
    WrappedComponent.displayName || WrappedComponent.name || ''
  }`;

  return Wrapper;
};

export default withLazyLoad;
