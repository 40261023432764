import React from 'react';
import { IconProps } from '@/types/icon';

function CareCheck({ width, height }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 77 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1425_28144)">
        <path
          d="M1.89798e-06 44.7957C9.89347e-07 24.0086 17.0459 1.99866 42.0266 1.38747C67.0073 0.776279 76.9557 22.7607 77 39.2931C77.0496 57.9401 65.5968 75.576 43.4063 75.6246C23.8055 75.6679 2.80661e-06 65.5829 1.89798e-06 44.7957Z"
          fill="#E6F3EE"
        />
      </g>
      <path
        d="M31.4817 26.9459C28.4555 28.027 23.3583 28.3874 21.1922 28.027C21.1922 31.0901 20.7595 38.7297 21.1927 44.7838C21.7342 52.3514 28 55 28 55L39.6046 61L49.8937 55.0541C52.7818 53.2523 58 49.8884 58 43.1466V27.471C56.3754 27.471 52.8179 27.8108 49.3521 26.9459C45.8864 26.0811 41.4097 22.6216 39.6046 21C38.3411 22.2613 35.1305 25.6425 31.4817 26.9459Z"
        stroke="#213F5F"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <path
        d="M41.8874 37.9425L46.9274 38.7425C47.0286 38.7581 47.1235 38.8011 47.202 38.8669C47.2804 38.9326 47.3393 39.0186 47.3723 39.1155C47.4053 39.2124 47.4111 39.3165 47.3891 39.4165C47.367 39.5164 47.318 39.6085 47.2474 39.6825L43.6074 43.1425L44.4074 48.3425C44.4074 48.8225 43.9274 49.1425 43.6074 48.9825L39.2074 46.4625L34.6274 48.8225C34.1474 48.9825 33.6874 48.6625 33.8474 48.2025L34.7874 43.1425L31.1674 39.5225C30.8474 39.2025 31.0074 38.5825 31.4674 38.5825L36.5274 37.7825L38.7274 33.3825C38.7498 33.2745 38.8088 33.1774 38.8944 33.1077C38.98 33.038 39.087 33 39.1974 33C39.3078 33 39.4148 33.038 39.5004 33.1077C39.586 33.1774 39.645 33.2745 39.6674 33.3825L41.8874 37.9425Z"
        fill="#213F5F"
      />
      <defs>
        <clipPath id="clip0_1425_28144">
          <rect width="77" height="77" fill="white" transform="translate(0 77) rotate(-90)" />
        </clipPath>
      </defs>
    </svg>
  );
}

CareCheck.defaultProps = {
  width: '77',
  height: '77',
};

export default CareCheck;
