import getConfig from 'next/config';
import Cookies from 'universal-cookie';
import React from 'react';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { AnalyticsEventName, CtaLocation, CtaText, ScreenName } from '@/types/enums';

import {
  TOP_NAV_LINKS,
  CZEN_VISITOR_COOKIE_KEY,
  CZEN_SESSION_COOKIE_KEY,
  SITTER_DEFAULT_VERTICAL_MW_ENROLL,
  SITTER_DEFAULT_VERTICAL_DT_ENROLL,
  SEEKER_VERTICAL_TRIAGE_PAGE,
  SITTER_ENROLL,
  VERTICALS,
  LIST_YOUR_BUSINESS_URL,
  VERTICALS_NAMES,
} from '@/constants';
import logger from '@/lib/clientLogger';

const {
  publicRuntimeConfig: { CZEN_GENERAL },
} = getConfig();

export const logInHandler = async () => {
  const cookies = new Cookies();
  const czenVisitorId = cookies.get(CZEN_VISITOR_COOKIE_KEY);
  const czenSessionId = cookies.get(CZEN_SESSION_COOKIE_KEY);

  await AnalyticsHelper.logEvent({
    name: AnalyticsEventName.CTA_INTERACTED_VHP,
    data: {
      screen_name: ScreenName.VISITOR_HOMEPAGE,
      enrollment_initiated: false,
      cta_text: CtaText.LOG_IN,
      cta_location: CtaLocation.VHP_HEADER,
      vertical: VERTICALS_NAMES.unknown,
    },
  });

  logger.info({
    name: AnalyticsEventName.CTA_INTERACTED_VHP,
    data: {
      screen_name: ScreenName.VISITOR_HOMEPAGE,
      enrollment_initiated: false,
      cta_text: CtaText.LOG_IN,
      cta_location: CtaLocation.VHP_HEADER,
      vertical: VERTICALS_NAMES.unknown,
      czenVisitorId,
      czenSessionId,
    },
  });

  window.location.assign(`${CZEN_GENERAL}${TOP_NAV_LINKS.logIn}`);
};

export const applyToJobsHandler = (
  isMW?: boolean,
  ctaLocation: string = CtaLocation.VHP_HEADER,
  routeToTriagePage: boolean = false,
  screenName: string = ScreenName.VISITOR_HOMEPAGE
) => {
  AnalyticsHelper.logEvent({
    name: AnalyticsEventName.CTA_INTERACTED_VHP,
    data: {
      screen_name: screenName,
      enrollment_initiated: true,
      cta_location: ctaLocation,
      cta_text: CtaText.APPLY_TO_JOBS,
      vertical: VERTICALS_NAMES.unknown,
    },
  });

  if (routeToTriagePage) {
    window.location.assign(`${CZEN_GENERAL}${SEEKER_VERTICAL_TRIAGE_PAGE}?memberType=sitter`);
    return;
  }

  if (isMW) {
    window.location.assign(`${CZEN_GENERAL}${SITTER_DEFAULT_VERTICAL_MW_ENROLL}`);
  } else {
    window.location.assign(`${CZEN_GENERAL}${SITTER_DEFAULT_VERTICAL_DT_ENROLL}`);
  }
};

export const handleUserNeedSelected = (
  isProvider: boolean,
  isDesktop: boolean,
  routeToTriagePage: boolean = false
) => {
  if (isProvider) {
    if (routeToTriagePage) {
      window.location.assign(`${CZEN_GENERAL}${SEEKER_VERTICAL_TRIAGE_PAGE}?memberType=sitter`);
    } else if (isDesktop) {
      window.location.assign(`${CZEN_GENERAL}${TOP_NAV_LINKS.becomeCaregiver}`);
    } else {
      window.location.assign(`${CZEN_GENERAL}${SITTER_ENROLL}${VERTICALS.CHILD_CARE}`);
    }
  } else {
    window.location.assign(`${CZEN_GENERAL}${SEEKER_VERTICAL_TRIAGE_PAGE}`);
  }
};

export const handleListYourBusinessClick = (
  e: React.MouseEvent<HTMLElement>,
  location = 'VHP Footer'
) => {
  const element = e.target as HTMLElement;
  if (element.textContent?.trim() === 'List your business') {
    e.preventDefault();

    AnalyticsHelper.logEvent({
      name: AnalyticsEventName.CTA_INTERACTED_VHP,
      data: {
        screen_name: ScreenName.VISITOR_HOMEPAGE,
        enrollment_initiated: false,
        cta_text: CtaText.LIST_YOUR_BUSINESS,
        cta_location: location,
        vertical: VERTICALS_NAMES.unknown,
      },
    });

    window.location.assign(`${CZEN_GENERAL}${LIST_YOUR_BUSINESS_URL}`);
  }
};
